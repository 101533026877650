import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://69a5da1d7c6d9dc287aa38e9192fad1d@o255771.ingest.sentry.io/4506270594433025",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});